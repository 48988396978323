
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import * as THREE from 'three';

import React, { useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from '@react-three/drei';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

function Scene() {
    const meshRef = useRef()
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    //  useFrame((state, delta) => (meshRef.current.rotation.y += delta *10))

    const gltf = useLoader(GLTFLoader, '/homeHub.glb')
    const camera = new THREE.PerspectiveCamera(10, window.innerWidth / window.innerHeight, 0.1, 1000);

    gltf.scene.add(camera)

    const model = gltf.scene
    model.position.z = 0


    return <primitive object={gltf.scene} ref={meshRef} />
}

function BuyInfoPage() {
    return (

        <div className="App-header">

            <Container>
                <Row>
                    <Col>
                    <div className=" mb-4 border-0" style={{ margin: '5%', padding: '5%' }}>
               
            
                    <h1>Buy Pre Release</h1>
                    <p>Be among the first to experience the future of smart home technology with Home Hub's pre-release edition:</p>
        <ul>
            <li>Gain early access to the next generation of smart sensors.</li>
            <li>Shape Home Hub's development with your feedback.</li>
            <li>Enjoy exclusive pre-release perks and discounts.</li>
            <li>Join our journey in shaping the future of smart living.</li>
            <li>Receive priority support from our dedicated team.</li>
        </ul>
        <p>Don't miss your chance to lead the smart home revolution. Secure your spot today!</p>

            </div>
                    </Col>
                    <Col>
                    <Card className="card mb-4 border-0" style={{margin: '15%' }}>
                <Canvas
                    style={{ height: '25rem' }}
                    camera={{ position: [0, 0, 100], fov: 30 }}>
                    <ambientLight intensity={Math.PI / 2} />
                    <OrbitControls
                        enableZoom={false}
                        enablePan={false}
                    />
                    <spotLight position={[100, 100, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
                    <pointLight position={[-100, -100, -10]} decay={0} intensity={Math.PI} />
                    <Scene />
                </Canvas>
                <Card.Body>
                    <Card.Title>Home Hub - Pre Realase</Card.Title>
                    <Card.Text>
                        Our cutting-edge smart home sensor blends seamlessly into your living space, revolutionizing the way you interact with your environment.
                    </Card.Text>
                    <Button variant="primary">Buy 149.00€</Button>
                </Card.Body>
            </Card>
                    </Col>
                </Row>
            </Container>


        </div>
    );
}

export default BuyInfoPage; 