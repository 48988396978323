import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import BuyInfoPage from './BuyInfoPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="/">Heliostat</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="development">Development</Nav.Link>
            <Nav.Link href="/features">Features</Nav.Link>
            <Nav.Link href="/buy">Buy</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Routes>
      <Route exact path="/" element={<App />} />
      <Route exact path="/buy" element={<BuyInfoPage />} />
      </Routes>
    
    <footer className="footer mt-auto py-3 bg-light">
    <div className="container text-center">
        <p>© 2024 Heliostat</p>
        <p>Contact us: contact@heliostat.io</p>
        {/* <p>Address: 123 Street, City, Country</p> */}
      </div>
    </footer>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
