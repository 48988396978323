import Fade from 'react-bootstrap/Fade';
import Collapse from 'react-bootstrap/Collapse';
import React, { useState, useEffect } from 'react'


function FlyInText(props) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const element = document.getElementById('collapse-text');

            if (element && element.getBoundingClientRect().top < (window.innerHeight /3)) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call handleScroll initially to check on load
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div style={{ minHeight: '150px' }}>
                {/* <Collapse in={open} dimension="height"> */}
                    <Fade in={open}>
                        <div id="collapse-text">
                            {props.children}
                        </div>
                    </Fade>
                {/* </Collapse> */}
            </div>
        </>
    );
}

export default FlyInText;